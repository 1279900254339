@import '~@fortawesome/fontawesome-free/css/all.css';
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,300italic,400italic,600italic&display=swap');
@import url('https://fonts.googleapis.com/css?family=Prompt:300,400,600,700,300italic,400italic,600italic&display=swap');
@import url('https://fonts.googleapis.com/css?family=Oswald');

@import "~bootstrap/scss/bootstrap";
@import "libs/vivify/vivify.min.css";
@import "libs/magnific/magnific-popup.css";
@import "libs/daterangepicker/daterangepicker.css";
//@import "config";
@import "components";
