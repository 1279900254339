@import url('https://fonts.googleapis.com/css?family=Oswald');
*
{
	margin: 0;
	padding: 0;
	border: 0;
	box-sizing: border-box
}

.fl-left{float: left}

.fl-right{float: right}

.container
{
	width: 90%;
	margin: 0 auto 30px;
}

h1
{
	text-transform: uppercase;
	font-weight: 900;
	border-left: 10px solid #fec500;
	padding-left: 10px;
	margin-bottom: 30px
}

.row{overflow: hidden}

.card
{
	display: table-row;
	width: 48%;
	background-color: #fff;
	color: #989898;
	font-family: 'Oswald', sans-serif;
	text-transform: uppercase;
	border-radius: 4px;
	position: relative;
	margin: 1% 1% 1% 1%;
}

.date
{
	display: table-cell;
	width: 13%;
	position: relative;
	text-align: center;
	border-right: 2px dashed #dadde6
}

.date:before,
.date:after
{
	content: "";
	display: block;
	width: 30px;
	height: 30px;
	background-color: #DADDE6;
	position: absolute;
	top: -15px ;
	right: -15px;
	z-index: 1;
	border-radius: 50%
}

.date:after
{
	top: auto;
	bottom: -15px
}

.date time
{
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%)
}

.date time span{display: block}

.date time span:first-child
{
	color: #2b2b2b;
	font-weight: 600;
}

.date time span:last-child
{
	text-transform: uppercase;
	font-weight: 600;
}

.card-cont
{
	display: table-cell;
	padding: 10px 10px 10px 50px;
	width: 55%;
}

.card-cont h3
{
	color: #3C3C3C;
	font-size: 130%
}




.card-cont .even-date time span
{
	display: block;
}

.card-cont button
{
	text-decoration: none;
	padding: 7px 10px 7px 10px;
	background-color: #1f3e59;
	color: #fff;
	border-radius: 0.25rem;
}

.card-cont button:hover
{
	background-color: #3b688e;
}

.count-input {
	width: 35px;
	-webkit-appearance: none;
	margin: 0;
	-moz-appearance: textfield;
	padding: 0;
	text-align: center;
}

@media screen and (max-width: 530px){
	.count-input {
		width: 35px;
		-webkit-appearance: none;
		-moz-appearance: textfield;
		padding: 0;
		text-align: center;
		margin-right: 0!important;
		margin-bottom: 10px!important;
	}

	.card-cont
	{
		display: table-cell;
		padding: 10px 10px 10px 30px;
		width: 55%;
	}

	.date
	{
		display: table-cell;
		width: 25%;
		position: relative;
		text-align: center;
		border-right: 2px dashed #dadde6
	}
}

@media screen and (max-width: 1070px) and (min-width: 861px){
	.count-input {
		width: 35px;
		-webkit-appearance: none;
		-moz-appearance: textfield;
		padding: 0;
		text-align: center;
		margin-right: 0!important;
		margin-bottom: 10px!important;
	}

	.card-cont
	{
		display: table-cell;
		padding: 10px 10px 10px 30px;
		width: 55%;
	}

	.date
	{
		display: table-cell;
		width: 25%;
		position: relative;
		text-align: center;
		border-right: 2px dashed #dadde6
	}
}

@media screen and (max-width: 860px)
{
	.card
	{
		display: block;
		float: none;
		width: 100%;
		margin-bottom: 10px
	}

	.card + .card{margin-left: 0}

	.card-cont .even-date,
	.card-cont .even-info
	{
		font-size: 75%
	}
}
