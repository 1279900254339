body
{
	background-color: #dadde6;
}

footer{
	background-color: #fff;
}

.main-color {
	color: #1f3e59;
}

a .main-color:hover {
	color: #3b688e;
}

.btn-main {
	color: #fff;
	background-color: #1f3e59;
	border-radius: 0.3rem;
}

.btn-main:hover {
	color: #fff;
	background-color: #3b688e;
}

.head-sc-color {
	color: #1f3e59;
}

.head-sc-icon {
	font-size: 30px;
}

.head-sc-icon:hover {
	font-size: 30px;
	color: #3b688e !important;
}

.h3-text {
	margin: 20px 0 20px 0;
	font-size: 32px;
	font-weight: 700;
	text-transform: uppercase;
	line-height: 36px;
	letter-spacing: 1px;
}

.hr-lg {
	width: 30%;
	border: 1px solid #1f3e59;
	margin-bottom: 30px;
	margin-left: auto;
	margin-right: auto;
}

.nav-link-menu {
	color: #1f3e59;
	font-size: 16px;
	font-weight: 700;
	text-transform: uppercase;
	line-height: 20px;
	letter-spacing: 0;
}

.nav-link-menu:hover {
	color: #3b688e !important;
}

.exchange-name {
	font-size: 1.25rem;
}

.exchange-city {
	font-size: 0.85rem;
	color: #3c3c3c;
	letter-spacing: 1px;
}

.exchange-hr {
	width: 100px;
	border: 1px solid #d13062;
	margin-left: auto;
	margin-right: auto;
}

.tickets-btn {
	letter-spacing: 1px;
	padding: 7px;
}

.notify-badge-schop {
	position: absolute;
	text-transform: uppercase;
	background: #1f3e59;
	text-align: center;
	color: white;
	padding: 4px 10px;
	font-size: 12px;
	left: 30px;
	border-radius: 50%;
	font-weight: 900;
	z-index: 99;
}

.buttoncard {
	position: relative;
	display: inline-block;
	margin-right: 5%;
}

@media screen and (max-width: 992px)
{
	.buttoncard {
		position: relative;
		display: inline-block;
		margin-right: 0%;
		float:right;
	}
}

.btn-te-first {
	background-color: #3ab54a;
	color: #fff;
}

.btn-te-first:hover {
	background-color: #2c993b;
	color: #fff;
}

.sc-card {
	text-decoration: none;
}

.sc-card:hover {
	text-decoration: none;
}

.list-group-item-sc-header {
	background-color: #043d5d;
	color: #fff;
}

.list-group-item-sc-footer {
	background-color: #f8f9fa;
	color: #000;
}

.list-group-item-sc-foot {
	background-color: #043d5d;
	color: #fff;
}

.count {
	-webkit-appearance: none;
	margin: 0;
	-moz-appearance: textfield;
}

.myAlert-top {
	position: fixed;
	top: 10px;
	width: 100%;
	z-index: 9999;
	text-align: center;
	text-transform: uppercase;
	opacity: 0.95;
}

#cookieinfo * {
	margin: 0;
	padding: 0;
}

#cookieinfo {
	position: fixed;
	font-size: 12px;
	font-family: Arial, Verdana, sans-serif;
	left: 0;
	bottom: 0;
	right: 0;
	background-color: #b3b6bf;
	padding: 20px;
	z-index: 1000;
	max-height: 100px;
	overflow: auto;
}

#cookieinfo h6 {
	font-size: 16px;
	font-weight: bold;
	color: #043d5d;
	margin-bottom: 7px;
}

#cookieinfo p {
	font-size: 12px;
	color: #1f1f1f;
	line-height: 1.5em;
}

#cookieinfo a {
	display: block;
	position: absolute;
	right: 10px;
	top: 10px;
	width: 22px;
	height: 22px;
	border-radius: 11px;
	line-height: 22px;
	font-size: 12px;
	color: #ffffff;
	text-align: center;
	text-decoration: none;
	background-color: #043d5d;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.75);
}

#cookieinfo a:hover {
	background-color: white;
	color: black;
}
